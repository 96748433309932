import '../assets/css/tayPageTemporizador.css'
import '../assets/css/colors.css'
import '../assets/css/fonts.css'
import { useEffect, useReducer, useRef, useState } from 'react'

import imagem1 from '../assets/imgs/taynaraContador/1.jpg'
import imagem2 from '../assets/imgs/taynaraContador/2.jpg'
import imagem3 from '../assets/imgs/taynaraContador/3.jpg'
import imagem4 from '../assets/imgs/taynaraContador/4.jpg'
import imagem5 from '../assets/imgs/taynaraContador/5.jpg'
import imagem6 from '../assets/imgs/taynaraContador/6.jpg'
import imagem7 from '../assets/imgs/taynaraContador/7.jpg'
import imagem8 from '../assets/imgs/taynaraContador/8.jpg'
import imagem9 from '../assets/imgs/taynaraContador/9.jpg'
import imagem10 from '../assets/imgs/taynaraContador/10.jpg'
import imagem11 from '../assets/imgs/taynaraContador/11.jpg'
import imagem12 from '../assets/imgs/taynaraContador/12.jpg'
import imagem13 from '../assets/imgs/taynaraContador/13.jpg'
import imagem14 from '../assets/imgs/taynaraContador/14.jpg'
import imagem15 from '../assets/imgs/taynaraContador/15.jpg'
import imagem16 from '../assets/imgs/taynaraContador/16.jpg'
import imagem17 from '../assets/imgs/taynaraContador/17.jpg'
import imagem18 from '../assets/imgs/taynaraContador/18.jpg'
import imagem19 from '../assets/imgs/taynaraContador/19.jpg'
import imagem20 from '../assets/imgs/taynaraContador/20.jpg'
import imagem21 from '../assets/imgs/taynaraContador/21.jpeg'
import imagem22 from '../assets/imgs/taynaraContador/22.jpeg'

const IntroButton = ({setChangeText, playButtonRef, handlePlay})=>{
  const [showDiv, setShowDiv] = useState(false)

  useEffect(() => {
    if(showDiv == true){
      setTimeout(() => { setChangeText(1)  }, 2000)
    }
  }, [showDiv]);

  return <div className="textoIntroBtn animaIntroButton" 
              onClick={() => { setShowDiv(1)  
                handlePlay()
              }}
              style={showDiv ? {transform: 'translateY(-500%)'} : {transform: 'translateY(0)'}}>
          <div className="button" ref={playButtonRef} onClick={handlePlay}>CLICA AI</div>
        </div>
}

export default function TayPageTempo(props){


    const [sliderController, setSlideController] = useState(0)
    const imagesArr = [imagem15, imagem16, imagem22, imagem21,  imagem17, imagem18, imagem19, imagem20, imagem2, imagem3, imagem14, imagem4, 
      imagem6, imagem7, imagem8, imagem9, imagem5, imagem10, imagem11, imagem12,
      imagem13, imagem1
    ]
    const imagesCountMax = 14
    
    const [tempoDecorrido, setTempoDecorrido] = useState(calcularTempoRestante("2024-10-18T22:00:00"));
    const [tempoDecorridoNamoro, setTempoDecorridoNamoro] = useState(calcularTempoRestante("2024-12-08T22:00:00"));


    function calcularTempoRestante(dataInicial) {
      const agora = new Date();
      const alvo = new Date(dataInicial);
      
      // Se a data inicial for no futuro, retorna tudo zero
      if (agora < alvo) {
        return { anos: 0, meses: 0, dias: 0, horas: 0, minutos: 0, segundos: 0 };
      }
    
      // Calculando a diferença em anos
      let anos = agora.getFullYear() - alvo.getFullYear();
      let meses = agora.getMonth() - alvo.getMonth();
      let dias = agora.getDate() - alvo.getDate();
    
      // Ajustar se o mês ainda não foi atingido no ano atual
      if (meses < 0) {
        anos -= 1;
        meses += 12;
      }
    
      // Ajustar se o dia ainda não foi atingido no mês atual
      if (dias < 0) {
        const ultimoDiaMesAnterior = new Date(agora.getFullYear(), agora.getMonth(), 0).getDate(); // Último dia do mês anterior
        dias += ultimoDiaMesAnterior;
        meses -= 1;
    
        if (meses < 0) {
          anos -= 1;
          meses += 12;
        }
      }
    
      // Calculando horas, minutos e segundos
      const diferenca = agora - alvo;
      const horas = Math.floor((diferenca / (1000 * 60 * 60)) % 24);
      const minutos = Math.floor((diferenca / (1000 * 60)) % 60);
      const segundos = Math.floor((diferenca / 1000) % 60);
    
      return { anos, meses, dias, horas, minutos, segundos };
    }
    

    const audioRef = useRef(null);
    const playButtonRef = useRef(null);
  
    
      const handlePlay = () => {
          console.log("HELLO")
          audioRef.current.play();
      };
    


    useEffect(() => {
      const intervalo = setInterval(() => {
        setTempoDecorrido(calcularTempoRestante("2024-10-18T22:00:00"));
      }, 1000);
  
      return () => clearInterval(intervalo); // Cleanup do intervalo
    }, []);

    useEffect(() => {
      const intervalo = setInterval(() => {
        setTempoDecorridoNamoro(calcularTempoRestante("2024-12-08T22:00:00"));
      }, 1000);
  
      return () => clearInterval(intervalo); // Cleanup do intervalo
    }, []);

     useEffect(() => {
    const intervaloSlider = setInterval(() => {
      if(changeText !== 1){
      setSlideController((prevSlider) =>
        prevSlider + 1 >= imagesCountMax ? 0 : prevSlider + 1
      );
    }
    }, 3000);

    return () => clearInterval(intervaloSlider); // Cleanup do intervalo
  }, [imagesCountMax]);

  const [changeText, setChangeText] = useState(0)

  const [changeAnimation, setChangeAnimation] = useState('')
  const [changeAnimation2, setChangeAnimation2] = useState('')

  useEffect(() => {
    if(changeText === 1){
      setTimeout(() => { setChangeAnimation('animaText')  }, 2000) 
      setTimeout(() => { setChangeAnimation2('animaText')  }, 2300) 
    }
  }, [changeText]);

    return <>
    
        <div className={`containerPrincipalTay TayPageTempo ${changeText === 0 ? 'introBtn' : ''}`}>
        <audio ref={audioRef} controls style={{opacity: 0}}>
            <source src={require('../assets/audio/SimplesAssim.mp3')} type="audio/mp3" />
            Seu navegador não suporta a tag de áudio.
          </audio>

        { changeText === 0   ? <IntroButton setChangeText={(val)=>{setChangeText(1)}}
                  playButtonRef={playButtonRef} handlePlay={handlePlay}/> : 
        <>
        

        <div className={`titleTop ${changeAnimation}`}>Rafael & Taynara</div>

            <div className={`title ${changeAnimation}`}>Que o nosso amor seja eterno e nossas memórias não se percam com o tempo</div>

            <div className={`leftContainer ${changeAnimation2}`}>
                    <div className="imageSlider" style={{'--imageTay': `url('${imagesArr[sliderController]}')`}}
                    onClick={()=>{
                      const sliderVal = sliderController+1
                      if(sliderVal >= imagesCountMax){
                          setSlideController(0)
                      }else{
                          setSlideController(sliderVal)
                      }
                      }}></div>

                </div>

            { changeAnimation2 !== '' ? <>
            
            <div className="subtext">
               Quem diria que seria tão difícil falar sobre o tempo, sendo que juntos o tempo muda sua maneira de contar,
              passando de uma maneira tão rápida e difícil de acompanhar. Sei que perto da eternidade, apenas aquele que 
              nos uniu e a tudo governa tem o controle sobre. Mas se posso pedir algo, eu desejo que o nosso amor seja bom e perfeito
              enquanto dure, e que nem o tempo, a distância, ou as dificuldades da vida nos separe.

              Esse site eu dedico ao nosso amor, que pode não ser infinito, mas também nunca passará na minha mente como algo finito. 
            </div>

            <div className="subtitle ">Oficialmente Juntos há:</div>
            
            <div className="temporizador ">

              <div className="countBox">
                <div>{tempoDecorridoNamoro.anos}</div>
                <div>anos</div>
              </div>

              <div className="countBox">
                <div>{tempoDecorridoNamoro.meses}</div>
                <div>meses</div>
              </div>

              <div className="countBox">
                <div>{tempoDecorridoNamoro.dias}</div>
                <div>dias</div>
              </div>

              <div className="countBox">
                <div>{tempoDecorridoNamoro.horas}</div>
                <div>horas</div>
              </div>

              <div className="countBox">
                <div>{tempoDecorridoNamoro.minutos}</div>
                <div>minutos</div>
              </div>

              <div className="countBox">
                <div>{tempoDecorridoNamoro.segundos}</div>
                <div>segundos</div>
              </div>

            </div>


            <div className="subtitle conheceu">Juntos há:</div>
            
            <div className="temporizador conheceu">

              <div className="countBox">
                <div>{tempoDecorrido.anos}</div>
                <div>anos</div>
              </div>

              <div className="countBox">
                <div>{tempoDecorrido.meses}</div>
                <div>meses</div>
              </div>

              <div className="countBox">
                <div>{tempoDecorrido.dias}</div>
                <div>dias</div>
              </div>

              <div className="countBox">
                <div>{tempoDecorrido.horas}</div>
                <div>horas</div>
              </div>

              <div className="countBox">
                <div>{tempoDecorrido.minutos}</div>
                <div>minutos</div>
              </div>

              <div className="countBox">
                <div>{tempoDecorrido.segundos}</div>
                <div>segundos</div>
              </div>

            </div> 
            
            
            
            
            
            </> : <></> }  </>}

        </div> 
    </>
}