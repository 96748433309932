import React from 'react';
import {BrowserRouter, Routes as Router, Route, useNavigate, useParams, useLocation} from 'react-router-dom';
import Main from './components/PaginaInicial/Main';
import TayPage from './components/TayPage';
import TayPageTempo from './components/TayPageTempo';

function Routes(){

    function Redireciona() {
        const navigate = useNavigate();
        const {pagina} = useParams();

        const location = useLocation();
        const params = new URLSearchParams(location.search).get('subPaginas');

        React.useEffect(() => {
            if(params){
                const parametrosArray = params.split(',').join("/");
                navigate(`/${parametrosArray}/`);
            }else{
                navigate(`/HomePage`);
            }
        }, [pagina, navigate]);
      
        return null; // renderiza nada enquanto redireciona
      }

    return(
        <BrowserRouter>
            <Router>
                <Route path="/HomePage" element={<Main />}/>
                <Route path="/" element={<Redireciona/>} />
                <Route path="/Taynara" element={<TayPage />}/>
                <Route path="/TaynDAWDara" element={<TayPage />}/>
                <Route path="/RafaelETaynara" element={<TayPageTempo />}/>
                
            </Router>
        </BrowserRouter>
    );
 };
export default Routes;

